
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/form/input-number/BasicUsage.vue";
import EUIDisabled from "@/views/resources/documentation/element-ui/form/input-number/Disabled.vue";
import EUISteps from "@/views/resources/documentation/element-ui/form/input-number/Steps.vue";
import EUIStepStrictly from "@/views/resources/documentation/element-ui/form/input-number/StepStrictly.vue";
import EUIPrecision from "@/views/resources/documentation/element-ui/form/input-number/Precision.vue";
import EUISize from "@/views/resources/documentation/element-ui/form/input-number/Size.vue";
import EUIControlsPosition from "@/views/resources/documentation/element-ui/form/input-number/ControlsPosition.vue";

export default defineComponent({
  name: "input-number",
  components: {
    EUIBasicUsage,
    EUIDisabled,
    EUISteps,
    EUIStepStrictly,
    EUIPrecision,
    EUISize,
    EUIControlsPosition
  },
  setup() {
    setCurrentPageTitle("Input Number");
  }
});
